<template>
  <plc-page :slide="true" :pic-urls="banner">
    <div class="page-index">
      <div class="module-wrapper bg-product">
        <div class="title-wrapper">
          <div class="title">解决方案</div>
          <div class="underline"></div>
        </div>
        <div class="solution-wrapper fixed-width">
          <img @click="openPage('solution', 0)" class="solution" src="../assets/images/product/p-1.jpg">
          <img @click="openPage('solution', 1)" class="solution" src="../assets/images/product/p-3.jpg">
          <img @click="openPage('solution', 2)" class="solution" src="../assets/images/product/p-2.jpg">
          <img @click="openPage('solution', 4)" class="solution" src="../assets/images/product/p-4.jpg">
        </div>
        <div @click="openPage('solution', 0)" class="btn-more">查看全部</div>
      </div>
      <div class="module-wrapper bg-about">
        <div class="title-wrapper">
          <div class="title">关于我们</div>
          <div class="underline"></div>
        </div>
        <div class="about-wrapper fixed-width">
          <div class="left">
            <p><span class="company">江苏普立兹智能系统有限公司</span>作为专业的人工智能物联网专家，运用“AI人工智能、物联网、移动互联网、云计算”等先进技术，专为畜禽领域量身开发智慧养殖整体解决方案，帮助广大养殖业者改善其工作及生活品质。
            </p>
            <p>
              目前，普立兹智能已拥有非常成熟的“易（e）▪ 养”智能养殖平台，提供两技术一服务：运用AI人工智能和IOT物联网两大技术，针对养殖场各种场景创新出多个解决方案，包括物联网智能环控，AI人工智能母猪测温、AI人工智能盘猪估重、智能电子饲喂、水电用量监测、料塔称重等系统，通过SaaS云平台服务，将环境、动物个体、饲喂、用水量、用电量等数据自动推送给用户进行高效分析和管理，打造真正的智慧养殖场，为用户提供更全面更智能的管理方案，提升养殖成绩，提高效率，降低成本。</p>
          </div>
          <img class="right" src="../assets/images/map.png">
        </div>
      </div>
      <div class="module-wrapper bg-case">
        <div class="title-wrapper">
          <div class="title">落地案例</div>
          <div class="underline"></div>
        </div>
        <div class="case-wrapper fixed-width">
          <div @click="changeExample('prev')" class="case-arrow">
            <img class="arrow" src="../assets/images/arrow-left.png">
          </div>
          <div class="case-slide-wrapper" ref="caseSlide">
            <div class="case-slide">
              <div @click="openPage('partner', null)" v-for="(item, index) in example" :data-index="index" :key="index" class="case-bg"
                   :class="`bg${index + 1}`">
                <div class="case-title" :data-index="index">{{item.name}}</div>
              </div>
            </div>
          </div>
          <div @click="changeExample('next')" class="case-arrow">
            <img class="arrow" src="../assets/images/arrow-right.png">
          </div>
        </div>
      </div>
      <div class="module-wrapper bg-contact">
        <div class="title-wrapper">
          <div class="title">联系我们</div>
          <div class="underline"></div>
        </div>
        <div class="contact-wrapper fixed-width">
          <div class="contact-item">
            <div class="key">
              <img class="icon" src="../assets/images/phone.png">
              <div>咨询热线</div>
            </div>
            <div class="value">400-900-5780</div>
          </div>
          <div class="contact-item">
            <div class="key">
              <img class="icon" src="../assets/images/location.png">
              <div>地址</div>
            </div>
            <div class="value">江苏省太仓市沙溪镇台北路6号<a target="_blank" style="text-decoration: none;" :href="mapUrl"><span
              class="map">[查看地图]</span></a></div>
          </div>
          <div class="contact-item">
            <div class="key">
              <img class="icon" src="../assets/images/email.png">
              <div>E-MAIL</div>
            </div>
            <div class="value">sales@plc-tek.com</div>
          </div>
        </div>
      </div>
    </div>
  </plc-page>
</template>

<script type="text/ecmascript-6">
  import PlcPage from '../components/page/Index'
  import BScroll from '@better-scroll/core'
  import { CASES } from '../util/constants'

  export default {
    name: 'Index',
    components: {
      PlcPage
    },
    mounted () {
      this.caseScroll = new BScroll(this.$refs.caseSlide, {
        scrollX: true,
        scrollY: false,
        click: true,
        probeType: 3
      })
      this.caseScroll.on('scroll', (page) => {
        const slideX = Math.abs(page.x)
        this.currentExample = Math.floor(slideX / this.slideWidth)
      })
      const hooks = this.caseScroll.scroller.actionsHandler.hooks
      hooks.on('click', (event) => {
        if (event._constructed) {
          const index = event.path[0].dataset.index
        }
      })
    },
    data () {
      return {
        banner: [require('../assets/images/banner/banner-1.jpg'), require('../assets/images/banner/banner-2.jpg'), require('../assets/images/banner/banner-3.jpg')],
        example: CASES,
        mapUrl: 'https://map.baidu.com/search/%E6%B1%9F%E8%8B%8F%E7%9C%81%E5%A4%AA%E4%BB%93%E5%B8%82%E6%B2%99%E6%BA%AA%E9%95%87%E5%8F%B0%E5%8C%97%E8%B7%AF6%E5%8F%B7/@13487518.625,3680994.03,14.19z?querytype=s&da_src=shareurl&wd=%E6%B1%9F%E8%8B%8F%E7%9C%81%E5%A4%AA%E4%BB%93%E5%B8%82%E6%B2%99%E6%BA%AA%E9%95%87%E5%8F%B0%E5%8C%97%E8%B7%AF6%E5%8F%B7&c=224&src=0&pn=0&sug=0&l=13&b=(13465894,3672419;13515046,3695811)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&device_ratio=2',
        currentExample: 0,
        slideWidth: 372
      }
    },
    methods: {
      openPage(page, param) {
        this.$router.push({
          name: page
        })
        localStorage.setItem('menu', param)
      },
      changeExample (type) {
        let currentExample = this.currentExample
        const exampleNum = this.example.length
        if (type === 'prev') {
          if (currentExample === 0) {
            // return
          } else {
            currentExample--
          }
        } else {
          if (currentExample === exampleNum - 3) {
            return
          }
          currentExample++
        }
        this.currentExample = currentExample
        this.caseScroll.scrollTo(-currentExample * this.slideWidth, 0, 500)
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-index
    text-align center

    .module-wrapper
      padding 45px 0
      display flex
      flex-direction column
      align-items center

      .title-wrapper
        .title
          font-size 28px
          color #040000
          margin-bottom 20px

        .underline
          width 60px
          height 1px
          background-color #679540
          margin 0 auto

    .solution-wrapper
      padding 100px 0

      .solution
        width 285px
        height 367px
        margin-right 20px
        cursor pointer

        &:last-child
          margin-right 0

    .btn-more
      width 130px
      padding 15px 35px
      font-size 14px
      color #656565
      border 1px #cccccc solid
      border-radius 3px
      cursor pointer
    .bg-product
      background-color #fff
    .bg-about
      background-color #f5f5f5

    .about-wrapper
      padding 30px 0
      display flex
      align-items center

      .left
        flex 1
        font-size 15px
        color #565454
        text-align justify
        text-indent 2em
        line-height 2

        .company
          color #92bd6f

      .right
        width 607px
        height 335px
        margin-left 30px

    .bg-case
      background-image url("../assets/images/index-solution-bg.png")
      background-repeat no-repeat
      background-size cover

    .case-wrapper
      padding 120px 0 90px
      display flex
      align-items center

      .case-slide-wrapper
        flex 1
        min-height 1px
        white-space nowrap
        overflow hidden
        margin 0 22.5px

        .case-slide
          display inline-block
          font-size 0
          height 221px

          .case-bg
            cursor pointer
            margin-right 45px
            display inline-block
            background-repeat no-repeat
            background-size 327px 221px
            height 221px
            width 327px

            .case-title
              display flex
              align-items center
              justify-content center
              height 221px
              width 327px
              font-size 25px
              color #fff

            &:last-child
              margin-right 0

            &.bg1
              background-image url("../assets/images/example/eg-1.jpg")

            &.bg2
              background-image url("../assets/images/example/eg-2.jpg")

            &.bg3
              background-image url("../assets/images/example/eg-3.jpg")
            &.bg4
              background-image url("../assets/images/example/eg-4.jpg")

            &.bg5
              background-image url("../assets/images/example/eg-5.jpg")

            &.bg6
              background-image url("../assets/images/example/eg-6.jpg")
            &.bg7
              background-image url("../assets/images/example/eg-7.jpg")

            &.bg8
              background-image url("../assets/images/example/eg-8.jpg")

      .case-arrow
        padding 40px 14px
        background-color #e6e6e6
        border-radius 4px
        cursor pointer

        .arrow
          width 14px
          height 24px

    .bg-contact
      background-image url("../assets/images/index-contact-bg.jpg")
      background-repeat no-repeat
      background-size cover

    .contact-wrapper
      padding 70px 0

      .contact-item
        margin-bottom 25px

        &:last-child
          margin-bottom 0

        .key
          display flex
          align-items center
          justify-content center
          font-size 18px
          color #999899
          margin-bottom 20px

          .icon
            margin-right 7px

        .value
          font-size 16px
          color #323333

          .map
            color #484d90
            margin-left 15px
            cursor pointer
</style>
